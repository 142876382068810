import { webAuth } from "@/common/auth0";
import { auth as config } from "@/common/config";
import { ref } from "vue";

let authenticator = {};
if (!config.disabled) {
  authenticator = webAuth();
}

// custom event bus class
class AuthEvents extends EventTarget {}

const AuthService = (function () {
  const idToken = ref(null);
  const profile = ref(null);
  const tokenExpiry = ref(null);

  const enabled = !config.disabled;

  const ID_TOKEN_KEY = "id_token";
  const LOGIN_KEY = "logged_in";

  const events = new AuthEvents();
  const webAuth = authenticator;

  // beforeUnmount() {
  //   this.events.removeAllListeners();
  // },

  // log user out
  function logout() {
    localStorage.removeItem(LOGIN_KEY);
    localStorage.removeItem(ID_TOKEN_KEY);
    idToken.value = null;
    tokenExpiry.value = null;
    profile.value = null;

    webAuth.logout({ returnTo: window.location.origin });
    events.dispatchEvent(new CustomEvent("authenticate", { detail: { loggedIn: false } }));
  }

  // store key parts of authentication result and emit authenticate event
  function storeLogin(authResult) {
    // capture logged in state
    idToken.value = authResult.idToken;
    profile.value = authResult.idTokenPayload;
    tokenExpiry.value = new Date(profile.value.exp * 1000); // JWT expiry is sent in secs

    // store raw token for apollo to use with backend
    localStorage.setItem(ID_TOKEN_KEY, authResult.idToken);
    localStorage.setItem(LOGIN_KEY, "true");

    events.dispatchEvent(
      new CustomEvent("authenticate", {
        detail: {
          loggedIn: true,
          profile: authResult.idTokenPayload,
          state: authResult.appState || {},
        },
      })
    );
  }

  return {
    // AUTHENTICATION STATUS

    // is the user currently authenticated?
    isAuthenticated() {
      const tokenActive = tokenExpiry.value && Date.now() < tokenExpiry.value;
      // right now tokenExpiry isn't persisted to local storage, so every new tab or browser
      // session will result in an auth redirect loop. could stash and recover this in
      // _storeLogin but that could be less secure?
      return tokenActive && localStorage.getItem(LOGIN_KEY) === "true";
    },

    enabled, // expose whether auth is enabled

    // handle callback request from Auth0 & parse out details
    checkAuthentication() {
      return new Promise((resolve, reject) => {
        // parses result out of url hash
        webAuth.parseHash((err, authResult) => {
          if (err) {
            logout();
            reject(err);
          } else {
            storeLogin(authResult);
            resolve(authResult.idToken);
          }
        });
      });
    },

    // LOGIN FUNCTIONS

    // start the user login flow, will take user to off-site login interface
    login(customState) {
      webAuth.authorize({ appState: customState });
    },

    logout, // expose logout method

    // LISTENER MANAGEMENT

    addListener(eventName, callback) {
      events.addEventListener(eventName, callback);
    },

    removeListener(eventName, callback) {
      events.removeEventListener(eventName, callback);
    },

    // OTHER OPERATIONS

    // request an email with instructions to change a user's password
    changePassword(email) {
      return new Promise((resolve, reject) => {
        webAuth.changePassword(
          { email, connection: "Username-Password-Authentication" },
          (err, message) => {
            if (err) {
              reject(err);
            } else {
              resolve(message);
            }
          }
        );
      });
    },

    renewTokens() {
      // get a new token set
      return new Promise((resolve, reject) => {
        if (localStorage.getItem(LOGIN_KEY) !== "true") {
          return reject("Not logged in");
        }
        // renew authentication status
        webAuth.checkSession({}, (err, authResult) => {
          if (err) {
            logout();
            reject(err);
          } else {
            storeLogin(authResult);
            resolve(authResult);
          }
        });
      });
    },
  };
})();

export default AuthService;

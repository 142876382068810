import { createRouter, createWebHistory } from "vue-router";
import auth from "@/services/authService";
import { useToasted } from "@/common/toasted";

import AuthCallback from "@/views/AuthCallback";
import CompanyList from "@/views/CompanyList";
import CompanyShow from "@/views/CompanyShow";
import FinancingDatabase from "@/views/FinancingDatabase";
import FundList from "@/views/FundList";
import FundShow from "@/views/FundShow";
import MemberList from "@/views/MemberList";
import MemberShow from "@/views/MemberShow";
import PortfolioOverview from "@/views/PortfolioOverview";
import ProgramOverview from "@/views/ProgramOverview";
import UserSettings from "@/views/UserSettings";
import ReportingCalendar from "@/views/ReportingCalendar";
import TestExceptions from "@/views/TestExceptions";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    // ---- Utility routes ----
    {
      path: "/callback",
      name: "callback",
      component: AuthCallback,
    },
    {
      path: "/kaboom",
      // generates a random exception to test exception handling
      component: TestExceptions,
    },
    // ---- User-facing routes ----
    {
      path: "/",
      name: "home",
      // component: Home
      redirect: { name: "members" },
    },
    {
      path: "/calendar",
      name: "calendar",
      component: ReportingCalendar,
      props: (route) => ({
        category: route.query.category,
        start: route.query.start,
        calendarOverride: route.query.calendar,
      }),
    },
    {
      path: "/companies",
      name: "companies",
      component: CompanyList,
      props: (route) => ({
        savedFilter: route.query.filter,
      }),
    },
    {
      path: "/companies/:id",
      name: "company-show",
      component: CompanyShow,
      props: true,
    },
    {
      path: "/financing",
      name: "financing",
      component: FinancingDatabase,
    },
    {
      path: "/funds",
      name: "funds",
      component: FundList,
    },
    {
      path: "/funds/:id",
      name: "fund-show",
      component: FundShow,
      props: (route) => ({
        id: route.params.id,
      }),
    },
    {
      path: "/members",
      name: "members",
      component: MemberList,
    },
    {
      path: "/members/:id",
      name: "member-show",
      component: MemberShow,
      props: (route) => ({
        id: route.params.id,
        tab: route.query.tab,
      }),
    },
    {
      path: "/overview",
      name: "overview",
      component: PortfolioOverview,
    },
    {
      path: "/program/overview",
      name: "program-overview",
      component: ProgramOverview,
      props: (route) => ({
        start: route.query.start,
      }),
    },
    {
      path: "/settings",
      name: "settings",
      component: UserSettings,
    },
  ],
});

// ensure user is logged in before showing them any pages
router.beforeEach((to, _from, next) => {
  if (!auth.enabled) return next(); // skip auth requirements
  if (to.path === "/callback") return next(); // in process of authenticating
  if (auth.isAuthenticated()) return next();

  auth.login({ target: to.fullPath });
});

// clear any active toasts on page transition
router.beforeEach((to, from, next) => {
  const toasted = useToasted();
  toasted.clear();
  next();
});

export default router;

import { sentry } from "@/common/config";
import * as Sentry from "@sentry/vue";

// https://docs.sentry.io/platforms/javascript/guides/vue/
export const initSentry = (app, router) => {
  if (!sentry.dsn) return;
  Sentry.init({
    app,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({
        router,
        routeLabel: "path",
      }),
    ],
    logErrors: true, // publish errors to console as well?
    tracingOptions: {
      trackComponents: true,
    },
    tracePropagationTargets: [
      "localhost",
      /^https: \/\/auger.heavybit.com/,
      /^https:\/\/auger-stg.heavybit.com/,
    ],
    // turn this down if we have real traffic, 0.1 or 0.2?
    // https://docs.sentry.io/platforms/javascript/guides/vue/performance/
    tracesSampleRate: 1.0,
  });
};

// make Sentry available as default or individual export
export { Sentry as default, Sentry };
